.projectDetailRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 645px;
    height: 70px;
}
.projectDetailRow .ant-form-item .ant-form-item-row{
    width: 645px !important;
    height: 70px !important;
} 
.projectDescriptionRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    width: 645px;
    height: 118px;
    /* margin-bottom: 30px; */
}
.projectDescriptionRow .ant-form-item .ant-form-item-row,
.projectDescriptionRow .ant-form-item-control-input,
.projectDescriptionRow .ant-input{
    width: 645px !important;
    height: 90px !important;
} 
.addProjectModalFooter {
    display: flex;
    padding: 26px 0px 30px 451px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    width: 645px;
    height: 96px;
}
.projectDetailField{
    border-radius: 6px;
    border: 1px solid var(--AI-Initiatives-Grey-20, #DDDDE3);
    background: #FFF;
    color: var(--AI-Initiatives-Grey-60, #6F6E87);
}
.projectLabelRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    height: 20px;
    width: 645px;
}
.labelText {
    color: var(--AI-Initiatives-Grey-80, #58576B);
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: var(--Title-Small-Tracking, 0.1px);
}
.requiredMark{
    color: var(--AI-Initiatives-Tertiary-Red-Tertiary-Dark-Red-60, #E43B13);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: var(--Title-Small-Tracking, 0.1px);
}
.projectDataText {
    color: var(--AI-Initiatives-Grey-100, #1E1E24);
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; 
}
.addProjectModalFooterRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    width: 194px;
    height: 40px;
}
.modalButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--AI-Initiatives-Grey-80, #58576B);
    width: 96px;
    height: 40px;
}
.modalButtonEnabled{
    background: #1D1D9C !important;
}
.modalButtonTextEnabled {
    color: var(--AI-Initiatives-White-100, #FFF) !important;
    font-size: 14px;
    line-height: 20px;
}
.modalButtonDisabled{
    background: var(--AI-Initiatives-Grey-20, #DDDDE3);
}
.modalButtonText {
    display: flex !important;
    height: 40px;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-size: 14px;
    line-height: 20px;
}
.addProjectModalFooter .ant-row {
    flex-flow: row !important;
}
.modalButton:hover, .modalButtonDisabled:hover{
    border-radius: 40px;
    border: 1px solid var(--AI-Initiatives-Grey-80, #58576B) !important;
    background: var(--AI-Initiatives-Grey-40, #9594A8) !important;
}
.modalButtonEnabled:hover{
    border-radius: 40px;
    background: var(--AI-Initiatives-Primary-Blue-Primary-Dark-Blue-80, #1D1D9C) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
}
.projectDetailRow .ant-form-item-control-input,
.projectDetailRow .ant-input {
    height: 35px !important;
}
.notification {
    display: flex;
    width: 344px !important;
    height: 68px !important;
    padding: 10px 0px 10px 16px !important;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--AI-Initiatives-Grey-80, #58576B);
    box-shadow: 0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px 0px rgba(0, 0, 0, 0.30);
}
.notificationText {
    color: var(--AI-Initiatives-White-100, #FFF);
    font-size: 14px;
    line-height: 18px; 
    text-align: start;
}
.ant-notification-notice-icon-success, .ant-notification-notice-icon-error {
    width: 0px;
}
.notification .ant-notification-notice-description {
    margin: 0px !important;
    height: 40px !important;
    width: 280px !important;
}
.notification .ant-notification-notice-message{
    margin-bottom: 0px !important;
}
.notification .ant-notification-notice-content{
    height: 40px !important;
}
.notificationCloseIcon {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 24px;
    height: 24px;
}