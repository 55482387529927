/* Base styles */
.ant-layout-content {
    margin-top: 0px !important;
    height: 100vh; /* Use 100vh to make the content height responsive */
    padding: 0px !important;
}

.margin-5 {
    margin: 5px;
}

.ant-card-head-title {
    text-overflow: unset !important;
    white-space: unset !important;
}

.highlight {
    background-color: #ffffff;
    color: #4096ff;
    border-color: #4096ff;
}

.project-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
}

.project-card .ant-card-body {
    height: 0px;
    padding: 0px;
}

.project-card .ant-card-cover > * {
    border-radius: 0 0 8px 8px !important;
    margin: 0;
}

.project-card {
    border: 0px;
}

.main-container {
    display: flex;
}

.content {
    margin-left: 290px; /* Adjust to the width of the sidebar */
    padding: 25px;
    width: calc(100% - 290px); /* Adjust to the width of the sidebar */
    height: 100vh; /* Use 100vh to make the content height responsive */
}

/* Responsive styles */
@media (max-width: 1440px) {
    .content {
        margin-left: 294px; 
        width: calc(100% - 294px); 
    }
}

@media (max-width: 1200px) {
    .content {
        margin-left: 250px; 
        width: calc(100% - 250px); 
    }
}

@media (max-width: 1024px) {
    .content {
        margin-left: 220px; 
        width: calc(100% - 220px); 
    }
}

@media (max-width: 768px) {
    .content {
        margin-left: 200px; 
        width: calc(100% - 200px); 
    }
}

@media (max-width: 480px) {
    .content {
        margin-left: 180px; 
        width: calc(100% - 180px); 
    }

    .project-image {
        height: 120px; 
    }

    .highlight {
        font-size: 14px; 
    }
}