/* Sidebar.css */
.sidebar {
    width: 294px;
    height: 100vh; /* Use 100vh to make the sidebar height responsive */
    position: fixed;
    left: 0;
    top: 0;
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
    transition: width 0.3s;
}

.sidebar.collapsed {
    width: 90px;
}

.ilLogo {
    display: flex;
    width: 142.679px;
    height: 50px;
    padding-bottom: 0.022px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 25px 124px 20px 27px;
}

.divider {
    width: 100%; /* Make the divider width responsive */
    height: 1px;
    background: #DDDDE3;
}

.sidebarContent {
    display: flex;
    width: calc(100% - 46px); /* Adjust width to fit within the sidebar */
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 20px 23px; /* Adjust margin to fit within the sidebar */
}

.screenName {
    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    color: #1E1E24;
    text-align: center;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.screenName:active, .screenName.selected {
    background: #DDDDE3 !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

a:hover {
    color: #1E1E24 !important;
}

.link {
    text-decoration: none;
    color: #1E1E24;
    display: flex;
    align-items: center;
    width: 100%;
}

.link .icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
}

/* Responsive styles */
@media (max-width: 1200px) {
    .sidebar {
        width: 250px;
    }

    .sidebar.collapsed {
        width: 80px;
    }

    .ilLogo {
        width: 120px;
        margin: 20px 100px 15px 20px;
    }

    .screenName {
        font-size: 14px;
    }

    .link .icon {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 1024px) {
    .sidebar {
        width: 200px;
    }

    .sidebar.collapsed {
        width: 70px;
    }

    .ilLogo {
        width: 100px;
        margin: 15px 80px 10px 15px;
    }

    .screenName {
        font-size: 12px;
    }

    .link .icon {
        width: 18px;
        height: 18px;
    }
}

@media (max-width: 768px) {
    .sidebar {
        width: 150px;
    }

    .sidebar.collapsed {
        width: 60px;
    }

    .ilLogo {
        width: 80px;
        margin: 10px 60px 5px 10px;
    }

    .screenName {
        font-size: 10px;
    }

    .link .icon {
        width: 16px;
        height: 16px;
    }
}

@media (max-width: 480px) {
    .sidebar {
        width: 120px;
    }

    .sidebar.collapsed {
        width: 50px;
    }

    .ilLogo {
        width: 60px;
        margin: 5px 40px 0px 5px;
    }

    .screenName {
        font-size: 8px;
    }

    .link .icon {
        width: 14px;
        height: 14px;
    }
}