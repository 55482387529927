.projectScreen {
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    align-items: flex-end;
    gap: 25px;
    background: var(--AI-Initiatives-White-100, #FFF);
}

.projectScreenTitle {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    align-self: stretch;
}

.projectTitleDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    height: auto;
}

.projectText1 {
    color: var(--AI-Initiatives-Grey-100, #1E1E24);
    font-family: 'Inter', sans-serif !important;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: var(--Display-Medium-Tracking, 0px);
    align-self: stretch;
}

.projectText2 {
    color: var(--AI-Initiatives-Grey-80, #58576B);
    font-family: 'Inter', sans-serif !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.projectTableRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    height: auto;
}

.projectTable {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
}

.projectTable .ant-table-column-has-sorters{
    width: 500px !important;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
    display: none; /* To hide the default sort icons */
}

.ant-table-tbody > .ant-table-row {
    width: 1096px !important;
    height: 56px !important;
}

.ant-table-row {
    height: 56px !important;
}

.ant-table-cell-row-hover, .menuButton:hover, .menuButton.row-hover {
    background: var(--AI-Initiatives-Grey-00, #F4F4F6) !important;
    border: none !important;
    border-color: #FFF !important;
}

.selectedRow, .menuButton.row-selected {
    background-color: var(--AI-Initiatives-Grey-20, #DDDDE3) !important; /* Change the background color for the selected row */
    border: none !important;
    border-color: #FFF   !important;
}

.ant-table-tbody > tr.ant-table-row-selected > td.ant-table-cell {
    background-color: #DDDDE3 !important;
}
td .ant-table-cell {
    width: 335px !important;
    height: 56px !important;
    background: var(--AI-Initiatives-White-100, #FFF) !important;
}
.ant-table-cell {
    padding: 0px !important;
}
th.ant-table-cell::before {
    width: 0px !important;
}
.projectNameWithImage {
    display: flex;
    height: 56px;
    align-items: center;
    gap: 10px;
}

.projectImageRow {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 6px;
}

.menuButton, .selectedMenuButton {
    display: flex;
    width: 32px;
    height: 36px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border-color: #FFF;
    background: var(--AI-Initiatives-White-100, #FFF);
    margin: 10px;
}

.selectedMenuButton {
    background: var(--AI-Initiatives-Grey-20, #DDDDE3) !important;
    border: none !important;
}

.menuPopover .ant-popover-content {
    width: 120px !important;
    height: 88px !important; 
}

.menuPopover .ant-popover-inner {
    width: 101px !important;
    height: 68px !important; 
    display: inline-flex;
    padding: 10px !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    border-radius: 10px;
    border: 1px solid var(--AI-Initiatives-Grey-20, #DDDDE3);
    background: var(--AI-Initiatives-White-100, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.menuContentButton {
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 20px;
    width: 102px;
    height: 33px;
    border-color: #FFF !important;
    align-self: stretch;
}

.menuContentSelectedButton, .menuContentButton:hover {
    border-radius: 6px;
    background: var(--AI-Initiatives-Grey-20, #DDDDE3) !important;
    display: flex;
    padding: 8px 10px;
    align-items: center;
    gap: 20px;
    width: 102px;
    height: 33px;
    border-color: #FFF !important;
    align-self: stretch;
}

.menuContentButtonText {
    font-weight: 400 !important;
}

.tableRowText {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: start;
}

th {
    background: var(--AI-Initiatives-Grey-20, #DDDDE3) !important;
    height: 50px !important;
    padding: 15px 0 !important;
    color: var(--AI-Initiatives-Grey-80, #58576B) !important;
    font-size: 14px;
    line-height: 18px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
}

.rightAlign {
    display: flex;
    justify-content: end;
    width: 30px;
}

.columnTitleWithSorter {
    display: flex;
    align-items: center;
}
.sorterIcon {
    margin-left: 15px; 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    background-image: url('../assets/icons/sortArrows.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.sorterIcon.ascend {
    background-image: url('../assets/icons/upSortArrow.svg');
}
.sorterIcon.descend {
    background-image: url('../assets/icons/downSortArrow.svg');
}
.deleteProjectModal .ant-modal-content {
    display: flex;
    width: 529px;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 30px;
    border-radius: 20px;
    background: var(--AI-Initiatives-White-100, #FFF);
    height: 238px;
    padding: 0px !important;
}
.deleteProjectModal .ant-modal-header {
    display: flex;
    height: 64px;
    /* padding: 14px 30px 14px 40px; */
    justify-content: space-between;
    align-items: center;
    /* align-self: stretch; */
    border-radius: 20px 20px 0px 0px;
    background: var(--Neutral-White-60, #F1F1F1);
    width: 529px;
}
.deleteProjectModal .ant-modal-title {
    display: flex;
    justify-content: space-between;
    width: 529px;
    padding: 14px 30px 14px 40px;
    align-items: center;
}
.deleteProjectModal .ant-modal-body {
    display: flex;
    width: 449px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    height: 18px;
}
.deleteProjectModal .ant-modal-footer {
    display: flex;
    padding: 26px 40px 30px 284px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    margin-top: 0px !important;
}
.deleteProjectModal .ant-btn:not(.closeIconButton) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid var(--AI-Initiatives-Grey-80, #58576B);
    width: 96px;
    height: 40px;
}
.deleteProjectModal .ant-btn-primary {
    margin-inline-start: 16px !important;
    background: var(--AI-Initiatives-Tertiary-Red-Tertiary-Dark-Red-100, #7C0000);
}
.deleteProjectModal .ant-btn:hover:not(.closeIconButton){
    border-radius: 40px;
    background: var(--AI-Initiatives-Primary-Blue-Primary-Dark-Blue-80, #1D1D9C) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
}
.deleteProjectModal .ant-btn span {
    color: var(--AI-Initiatives-Grey-100, #1E1E24);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
}
.deleteProjectModal .ant-btn span:hover, 
.deleteProjectModal .ant-btn-primary span {
    color: var(--AI-Initiatives-White-100, #FFF);
}

/* Responsive styles */
@media (max-width: 1920px) {
    .projectScreen,
    .projectScreenTitle,
    .projectTableRow,
    .projectTable {
        width: 100%;
    }

    .projectText1 {
        font-size: 24px !important;
    }

    .projectText2 {
        font-size: 16px !important;
    }

    .projectTable .ant-table-column-has-sorters {
        width: 750px !important;
    }
}

@media (max-width: 1200px) {
    .projectScreen,
    .projectScreenTitle,
    .projectTableRow,
    .projectTable {
        width: 100%;
    }

    .projectText1 {
        font-size: 22px !important;
    }

    .projectText2 {
        font-size: 14px !important;
    }
    .projectTable .ant-table-column-has-sorters {
        width: 400px !important;
    }
}

@media (max-width: 1024px) {
    .projectScreen,
    .projectScreenTitle,
    .projectTableRow,
    .projectTable {
        width: 100%;
    }

    .projectText1 {
        font-size: 20px !important;
    }

    .projectText2 {
        font-size: 12px !important;
    }
    .projectTable .ant-table-column-has-sorters {
        width: 300px !important;
    }
}

@media (max-width: 768px) {
    .projectScreen,
    .projectScreenTitle,
    .projectTableRow,
    .projectTable {
        width: 100%;
    }

    .projectText1 {
        font-size: 18px !important;
    }

    .projectText2 {
        font-size: 10px !important;
    }
    .projectTable .ant-table-column-has-sorters {
        width: 200px !important;
    }
}

@media (max-width: 480px) {
    .projectScreen,
    .projectScreenTitle,
    .projectTableRow,
    .projectTable {
        width: 100%;
    }

    .projectText1 {
        font-size: 16px !important;
    }

    .projectText2 {
        font-size: 8px !important;
    }
    .projectTable .ant-table-column-has-sorters {
        width: 100% !important;
    }
}