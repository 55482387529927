.okta-header {
  background-color: rgb(29, 29, 186); 
  color: white;
  padding: 0 20px;
  text-align: left;
  height: 40px;
  display: flex;
  align-items: center;
}

.okta-header h1 {
  font-size: 24px; 
  line-height: 1.2; 
  margin: 0; 
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 93vh;
  background-color: #f4f4f4;
}

.errorBox {
  text-align: center;
  padding: 75px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 48px;
  color: blue;
}

.message {
  font-size: 24px;
  color: #333;
}

.resolution {
  font-size: 18px;
  color: #777;
}