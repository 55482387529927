/* Base styles */
.rowHeading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    height: auto;
}

.columnHeading1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 100%; */
    height: auto;
}

.dashboardHeading {
    color: #1E1E24;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
}

.heading {
    color: var(--AI-Initiatives-Grey-100, #1E1E24);
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.description {
    color: var(--AI-Initiatives-Grey-80, #58576B);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.columnHeading2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1 0 0;
    align-self: stretch;
    width: 100%;
    height: auto;
}

.rowContent {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
}

.sortSearchRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 38px;
    margin-bottom: 27px;
}

.sortButton {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    border: 1px solid #1E1E24;
    width: 104px;
    height: 36px;
    gap: 10px;
}

.sortIcon {
    width: 18px;
    height: 18px;
}

.sortButtonText {
    height: 16px;
    width: 43px;
}

.projectContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* flex-direction: column;
    align-items: flex-start; */
    gap: 18px;
    align-self: stretch;
    width: 100%;
    height: auto;
}

.projectCard {
    width: 100%;
    max-width: 259px;
    height: 205px;
    border-radius: 12px;
    display: flex;
    /* justify-content: center;
    align-items: center; */
    flex-shrink: 0;
    border: 1px solid var(--AI-Initiatives-Grey-20, #DDDDE3);
    background: var(--AI-Initiatives-Grey-00, #F4F4F6);
    /* padding: 10px; */
    flex-direction: column;
    /* gap: 10px; */
}

.projectImage {
    height: 137px;
    flex-shrink: 0;
    align-self: stretch;
    background: lightgray;
    /* margin: 0;
    padding: 0; */
}

.ant-card .ant-card-body {
    padding: 0px;
}

.projectDetails {
    display: flex;
    width: 100%;
    max-width: 226px;
    height: 40px;
    align-items: center;
    /* padding: 10px; */
    /* margin: 10px */
}

.ant-card-meta {
    margin: 13px 10px 10px 10px !important;
}

.ant-card-body .ant-card-meta-detail {
    width: 100% !important;
    max-width: 226px !important;
    height: 40px !important;
    display: flex !important;
}

.projectDescription {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
    width: 100%;
    max-width: 155px;
    height: 40px;
}

.ant-card-meta-title {
    width: 100% !important;
    max-width: 155px !important;
    height: 40px !important;
}

.projectTitle {
    color: var(--AI-Initiatives-Grey-100, #1E1E24);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.projectLastUpdate {
    color: var(--AI-Initiatives-Grey-80, #58576B);
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}

.projectIconButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 40px;
    height: 40px;
    margin-left: 37px;
    background: none;
    border: none;
    box-shadow: none;
}

.projectIconButton:hover,
.projectIconButton:focus,
.projectIconButton:active {
    background: none !important; 
    border: none !important; 
    box-shadow: none !important; 
}

/* .ant-card-meta-description {
    width: 40px !important;
    height: 40px !important;
} */
/* .ant-btn-default .projectIconButton{
    background: none;
    border: none;
}

.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-default:active .projectIconButton{
    background: none !important; 
    border: none !important; 
    box-shadow: none !important;
} */

.projectInfoIcon {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 8px;
}

.ant-popover-arrow {
    width: 0px !important;
    height: 0px !important;
}

.sortPopover .ant-popover-content,.sortPopover .ant-radio-group {
    width: 140px !important;
    height: 146px !important; 
}

.sortPopover .ant-popover-inner {
    width: 120px !important;
    height: 100px !important;
    display: inline-flex;
    padding: 10px !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    border-radius: 10px !important;
    border: 1px solid var(--AI-Initiatives-Grey-20, #DDDDE3);
    background: var(--AI-Initiatives-White-100, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 2px 6px 2px rgba(0, 0, 0, 0.15) !important;
}

.sortPopover .ant-popover-inner-content,
.sortPopover .ant-popover-inner-content .ant-form-item,
.ant-form-item .ant-form-item-row
{
    height: 80px !important;
    width: 120px !important;
}

.ant-radio-wrapper {
    width: 120px !important;
    height: 30px !important;
    align-items: center !important;
    margin-bottom:2px !important;
}

.radioButtons {
    width: 14px;
    height: 14px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
}
.ant-radio, .ant-radio-inner {
    width: 0px !important;
    height: 0px !important;
    display: none !important;
}

.ant-radio-wrapper span.ant-radio+*{
    /* padding: 8px 10px 8px 5px !important; */
    width: 120px !important;
    height: 30px !important;
    display: flex!important;
    align-items: center !important;
    padding: 0px !important;
    margin-right: 0px !important;
}
.sortText {
    height: 16px !important;
    display: flex!important;
    color: var(--AI-Initiatives-Grey-60, #6F6E87);
    font-family: 'Inter', sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.projectInfoModal .ant-modal-content{
    display: flex;
    width: 556px;
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    gap: 30px;
    border-radius: 20px;
    background: var(--AI-Initiatives-White-100, #FFF);
    height: 274px;
    padding: 0px !important;
}
.projectInfoModal .ant-modal-header{
    display: flex;
    height: 64px;
    /* padding: 14px 30px 14px 40px; */
    justify-content: space-between;
    align-items: center;
    /* align-self: stretch; */
    border-radius: 20px 20px 0px 0px;
    background: var(--Neutral-White-60, #F1F1F1);
    width: 556px;
}
.projectInfoModal .ant-modal-title{
    display: flex;
    justify-content: space-between;
    width: 556px;
    padding: 14px 30px 14px 40px;
    align-items: center;
}
.projectInfoModal .ant-modal-body {
    display: flex;
    padding: 0px 40px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    height: 54px;
    width: 476px;
}
.projectInfoModal .ant-modal-footer {
    display: flex;
    padding: 26px 40px 30px 428px;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    margin-top: 0px !important;
}
.projectInfoModal .ant-btn-primary {
    display: none;
}
.projectInfoModal .ant-btn:not(.closeIconButton, .ant-btn-primary) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: var(--AI-Initiatives-Primary-Blue-Primary-Dark-Blue-100, #00007C);
    height: 40px;
    width: 88px;
}
.projectInfoModal .ant-btn span {
    color: var(--AI-Initiatives-White-100, #FFF);
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; 
}
.projectInfoModal .ant-btn:hover:not(.closeIconButton, .ant-btn-primary){
    border-radius: 40px;
    background: var(--AI-Initiatives-Primary-Blue-Primary-Dark-Blue-80, #1D1D9C) !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30), 0px 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
}
.errorMessageDiv{
    display: inline-flex;
    padding: 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 250px;
    height: 134px;
}
.errorMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 250px;
    height: 124px;
}
.errorIconSpan{
    display: flex;
    width: 40px;
    height: 40px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: var(--AI-Initiatives-Grey-20, #DDDDE3);
}
.errorIcon {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
}
.errorMessageSpan{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 250px;
    height: 50px;
}
.paginationInError {
    display: none;
}
/* Responsive styles */
@media (max-width: 1440px) {
    .projectCard {
        width: 48%;
    }
}

@media (max-width: 1200px) {
    .projectCard {
        width: 48%;
    }
}

@media (max-width: 1024px) {
    .projectCard {
        width: 100%;
    }
}


@media (max-width: 768px) {
    .projectCard {
        width: 100%;
    }

    .sortSearchRow {
        flex-direction: column;
        align-items: flex-start;
    }

    .searchInput {
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    .dashboardHeading {
        font-size: 32px;
    }

    .heading {
        font-size: 20px;
    }

    .description {
        font-size: 14px;
    }

    .projectTitle {
        font-size: 12px;
    }

    .projectLastUpdate {
        font-size: 10px;
    }
}