/* Pagination.css */
.ant-pagination {
    font-family:'Inter', sans-serif !important;
}
.ant-pagination-item {
    border-radius: 50% !important; /* Make the pagination buttons circular */
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    width: 28px !important; 
    height: 28px !important; 
    margin: 0; 
    color: #1E1E24 !important; 
    font-size: 14px !important; 
    font-family: 'Inter', sans-serif !important; 
    min-width: 28px !important;
    font-style: normal !important;
    font-weight: 400 !important;
}

.ant-pagination-item a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 !important; 
    color:  #1E1E24 !important;
    font-size: 14px !important; 
    font-family: 'Inter', sans-serif !important; 
    border: #DDDDE3;
    width: 28px !important;
    height: 28px !important;
    border-radius: 55px 0px 0px 0px;
}

.ant-pagination-item-link {
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link{
    margin-top: 2px;
}

.ant-pagination-item-link:hover {
    background-color: #DDDDE3 !important;
    font-weight: 400 !important;
}

.ant-pagination .ant-pagination-item-active {
    background-color:  #DDDDE3 !important; 
    border: #DDDDE3 !important;
    width: 28px !important;
    height: 28px !important;
    border-radius: 50% !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-family: 'Inter', sans-serif !important;
}

.ant-pagination .ant-pagination-prev {
    width: 28px !important;
    height: 28px !important;
    min-width: 28px !important;
}

.ant-pagination-total-text { 
    position: absolute;
    left: 20px;
    font-family: 'Inter' sans-serif !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
  }

.non-clickable {
    pointer-events: none !important; /* to make ellipse non-clickable */
    cursor: default !important; /* Change cursor to default */
}

.ant-pagination-item-before-jump-next,
.ant-pagination-item-after-jump-prev {
    pointer-events: none !important; /* to make ellipse non-clickable */
    cursor: default !important; /* Change cursor to default */
}

/* common css */
.userInitialRow {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
}

.userInitials {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 12px 5px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    border: 3.4px solid var(--AI-Initiatives-White-100, #FFF);
    background: var(--AI-Initiatives-Grey-80, #58576B);
}

.userInitialsText {
    color: var(--AI-Initiatives-White-100, #FFF);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    width: 38px;
    flex-shrink: 0;
}

.addProjectBtnText {
    width: 69px;
    height: 16px;
}

.addProjectButton {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    border: 1px solid var(--AI-Initiatives-Grey-100, #1E1E24);
    width: 131px;
    height: 36px;
}

.addProjectIcon {
    width: 18px;
    height: 18px;
}
.buttonText {
    color: var(--AI-Initiatives-Grey-100, #1E1E24);
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
}
.dashboardDivider {
    width: 100%;
    height: 0px;
    background-color: #DDDDE3;
}

.searchInput {
    display: flex;
    width: 100%;
    max-width: 250px;
    height: 40px;
    padding: 10px 18px;
    align-items: center;
    align-self: stretch;
    border-radius: 37px;
    border: 1px solid var(--AI-Initiatives-Grey-20, #DDDDE3);
    background: var(--AI-Initiatives-White-100, #FFF);
    color: var(--AI-Initiatives-Grey-80, #58576B);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.searchIcon {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    margin-right: 12px;
}
.ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 0px !important;
}
.ant-input-affix-wrapper-focused, .searchInput:hover {
    border: 1px solid var(--AI-Initiatives-Grey-20, #DDDDE3) !important;
    box-shadow: none !important;
}
.addProjectModal, 
.addProjectModal .ant-modal-content {
    display: flex;
    width: 725px !important;
    flex-direction: column;
    align-items: center;
    border-radius: 20px !important;
    background: var(--AI-Initiatives-White-100, #FFF);
    height: 502px;
    padding: 0px !important;
}
.addProjectModal .ant-modal-header, .addProjectModalHeader {
    display: flex;
    height: 64px;
    width: 725px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 20px 20px 0px 0px !important;
    background: var(--Neutral-White-60, #F1F1F1) !important;
    margin-bottom: 30px !important;
}
.addProjectModal .ant-modal-title {
    display: flex;
    justify-content: space-between;
    width: 725px;
    padding: 14px 30px 14px 40px !important;
    align-items: center;
}
.closeIconButton {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 0px;
    background: var(--Neutral-White-60, #F1F1F1) !important;
}
.closeIcon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
}
.addProjectModal .ant-modal-body, .addProjectModal .ant-form {
    display: flex;
    width: 645px;
    height: 408px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.ant-modal-close {
    display: none !important;
}
.modalBodyText {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
}
.paginationRow {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    height: 40px;
    margin-top: 20px;
    position: relative;
    left: 50px;
}

.paginationRowFixed {
    display: flex;
    justify-content: flex-end;
    width: 75%;
    height: 40px;
    position: fixed;
    bottom: 0;
}

/* Responsive styles */
@media (max-width: 1440px) {
    .paginationRowFixed {
        width:70%;
    }
}

@media (max-width: 1200px) {
    .paginationRowFixed {
        width:70%;
    }
}
